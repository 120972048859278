import React from 'react';
import {Row, Col} from 'react-bootstrap'
import {Link} from 'gatsby';

export const Button = (props) => (
  <div className="progression-row pro-row-button" style={{paddingTop: '20px', paddingBottom: '20px', marginLeft: 'auto', marginRight: 'auto'}}>
    <div className="progression-btn-container">
      <Link className="pro-btn-invested icon-btn-pro"
        to={props.href} style={{paddingLeft: '25px', paddingRight: '25px', textDecoration: 'none'}}>
        {props.title}<i className="fa fa-angle-right"></i>
      </Link>
    </div>
  </div>
)

export const Title = (props) => {
  return (
  <Row noGutters style={{paddingBottom: '20px', paddingTop: '50px', marginLeft: '5px', marginRight: '5px'}}>
    <Col md={{span: 10, offset: 1}} style={{padding: 0}}>
      <div style={{textAlign: 'center', width: '100%'}}>
        <h6 style={{fontSize: '18px', color: '#a3a9c8', textAlign: 'center', marginBottom: '2px'}}>{props.section}</h6>
        <h3 style={{fontSize: '36px', lineHeight: '1.2', textAlign: 'center', marginBottom: '40px'}}>{props.header}</h3>
      </div>
    </Col>
  </Row>
  )
}

export const Job = (props) => (
  <Col md={6} sm={12} style={{padding: 0, display: 'inline-block'}}>
    <div style={{display: 'inline-block', width: '100%', border: '1px solid #eeefef', backgroundColor: '#FFF'}}>
      <div className="pro-news-press-item" style={{display: 'inline-block', verticalAlign: 'top', border: 'none', padding: '20px'}}>
      <img src={props['Logo'][0] &&  props['Logo'][0].url} style={{width: '70px', height: '70px'}} />
      </div>
      <div className="pro-news-press-item" style={{display: 'inline-block', border: 'none', padding: '20px'}}>
        <a href={props.Link} target="_blank">
          <h4 style={{fontSize: '22px'}}>{(props.Title || '').substr(0,30)}</h4>
          <h5>{props['Company Name']} {props.Location ? '| ' + (props.Location || '').substr(0,20) : null}</h5>
        </a>
      </div>
    </div>
  </Col>
)

export const Article = (props) => (
  <Col md={6} sm={12} style={{display: 'inline-block', padding: 0}}>
      <div className="pro-news-press-item" style={{height: '170px', border: '1px solid #eeefef', padding: '30px'}}>
        {props.company ? <h5 style={{fontWeight: 400, fontStyle: 'italic', marginBottom: '5px', color: '#5ac0a0'}}>Mentions {props.company.Name}</h5> : null}
        <a href={props.url} target="_blank" style={{textDecoration: 'none'}}>
          <h4 style={{fontSize: '18px', minHeight: '42px'}}>{props.title}</h4>
          <h5>{props.date} {props.source ? '| ' + props.source : null}</h5>
        </a>
      </div>
  </Col>
)

export const Person = (props) => (
  <div className="progression-masonry-item progression-masonry-col-3" style={{display: 'inline-block'}}>
      <div className="progression-masonry-inline-padding">
          <div className="invested-team-border">
              <div className="team" >
                  <div className="invested-team-index">
                      <div className="progression-team-featured">
                          <img src={props.data.Picture[0] && props.data.Picture[0].url} style={{height: '200px'}}/>
                      </div>
                      <h2 className="invested-team-title">{props.data.Name}</h2>
                      <div className="invested-excerpt-team">
                          {props.data.Title}
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
)

export const Company = (props) => (
  <Col md={3} sm={12} style={{padding: 0, backgroundColor: '#fff'}}>
    {console.log(props.company)}
    {props.company.Stage == 'Acquired' ?
      <div style={{position: 'absolute', textAlign: 'center', width: '100%', backgroundColor: '#5ac0a0', opacity: '.75', color: 'white', height: '40px', padding: '5px'}}>{props.company.Stage}</div>
    : null }
    <div className="portfolio-company" style={{border: '1px solid #eeefef', height: '240px', width: '100%', padding: '20px', display: 'inline-block'}}>
      <a href={props.company.Website} target="_blank" style={{textDecoration: 'none'}}>
        <div className="invested-portfolio-feature" style={{textAlign: 'center'}}>
          <img height="400" src={props.company['Logo'][0] && props.company['Logo'][0].url} style={{height: '90px', width: '90px'}} />
          <h3 style={{fontSize: '24px', textAlign: 'center', marginTop: '10px'}}>{props.company.Name}</h3>
          <h6 style={{fontSize: '18px', color: '#a3a9c8', textAlign: 'center', marginBottom: 0}}>{props.company.Description}</h6>
        </div>
      </a>
    </div>
  </Col>
)
