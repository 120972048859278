import React from 'react';
import Scroll from 'react-scroll';
import Layout from 'layouts';
import {Title, Person, Job, Article, Button, Company} from 'components/site';
import {Row, Col, Modal, Carousel} from 'react-bootstrap';
import HeroImage from 'images/hero.jpg';
import YouTube from 'react-youtube';
import ReactPlayer from 'react-player'

export default (props) => (
      <Layout {...props} >
      <div className="page-content-pro" style={{paddingBottom: 50, background: '#f7f8f8'}}>
        <Hero />
        <Scroll.Element name="approach" />
        <Title section="Approach" header="ValueStream Ventures is an early-stage fund focused on B2B platforms built around valuable data"/>
        <Approach />
        <Title section="Portfolio" header={`${props.pageContext.companies.length} platforms built on the world's most valuable resource`} />
        <Portfolio {...props.pageContext} />
        <Title section="Team" header="A mix of Tech, Entrepreneurship, and Finance"/>
        <Team {...props.pageContext} />
        <Title section="Interviews" header="Insights from founders in the field"/>
        <Interviews {...props.pageContext}/>
        <Title section="News" header="What people are saying"/>
        <News {...props.pageContext} />
        <Title section="Jobs" header="It's all about who you work with"/>
        <Jobs {...props.pageContext} />
      </div>
      </Layout>
)

const Hero = (props) => (
  <div className="hero" style={{background: `url(/images/hero-bg.jpg) center`, backgroundRepeat: 'no-repeat', height: '650px', backgroundSize: 'cover'}}>
    <div style={{backgroundColor: 'rgba(0, 0, 0, 0.5)', position: 'absolute', top: 0, left: 0, width: '100%', height: '650px'}}>
      <h3 className="tp-caption tp-resizeme" style={{color: 'rgb(255, 255, 255)', textAlign: 'center', paddingLeft: '20px', paddingRight: '20px', lineHeight: '1.4'}}>Investing in the<br/>Intelligent Enterprise<br />
      </h3>
    </div>
  </div>
)

const Approach = (props) => {
  return (
    <div>
    <Row noGutters style={{marginLeft: '20px', marginRight: '20px'}}>
      <Col md={{span: 10, offset: 1}} style={{padding: 0}}>
        <div className="progression-row">
          <h5 className="pro-custom-heading">
        ValueStream Ventures is a thesis-driven venture firm. Our current thesis is that the next era of software opportunity will come from using AI to model how the world works and building scalable applications on those models. The Vertical AI layer has dramatically expanded the size of the existing Vertical SaaS market. Specifically, Vertical AI increases the operational leverage — by increasing revenues or decreasing costs — that software can provide to a business. Winning companies in this space will leverage their own sustainable, proprietary data acquisition platforms to train their own specialized AI models, creating a data feedback loop that increasingly sets them apart.<br/><br/>
        Our team's experience – working together in this space since 2013 – brings differentiated insights into how data can be leveraged to create value. Entrepreneurs and investors who partner with us find that we hustle, dig deep, and make outcomes happen.
        </h5>
      </div>
      </Col>
    </Row>
    <Row noGutters style={{marginLeft: '20px', marginRight: '20px', marginTop: '30px'}}>
      <Col md={{span: 10, offset: 1}} style={{padding: 0}}>
        <div style={{position: 'relative', paddingTop: '23.25%'}}>
          <ReactPlayer width='100%' height='100%' url='https://vimeo.com/394202762' style={{position: 'absolute', top: 0, left: 0}}/>
        </div>
      </Col>
    </Row>
    <Row noGutters style={{textAlign: 'center', marginTop: '30px'}}>
      <Button title="More about the VSV Approach" href="/approach" />
    </Row>
    </div>
  )
}

const Portfolio = (props) => {
  var companies = props.companies.slice(0,8)
  return (
  <div>
    <Row>
      {companies.map((company, i) => <Company key={i} company={company} />)}
    </Row>
    <Row noGutters style={{textAlign: 'center', marginTop: '30px'}}>
      <Button title="More Companies" href="/portfolio" />
    </Row>
    </div>
  )
}

class Interviews extends React.Component {
  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let section = params.get('section');
    if (section == 'interviews')
      Scroll.scroller.scrollTo('interviews');
    else
      Scroll.animateScroll.scrollToTop();
  }

  render() {
    console.log(this)
    const interview = this.props.interviews[0];
    return (
      <div>
    <Row noGutters>
      <Col md={{span: 10, offset: 1}} style={{padding: 0}}>

        <div style={{textAlign: 'center'}}>
        <Scroll.Element id="interviews" name="interviews" className="element"></Scroll.Element>
        <div style={{position: 'relative', paddingTop: '0%'}}>
          {/*<ReactPlayer width='100%' height='100%' url='https://vimeo.com/394202762' style={{position: 'absolute', top: 0, left: 0}}/>*/}
          <YouTube width='100%' height='100%' videoId={interview.ID}/>
        </div>

          <h3 style={{marginTop: '20px'}}>{interview.Name}, {interview.Title}</h3>
        </div>
      </Col>
    </Row>
    <Row noGutters style={{textAlign: 'center', marginTop: '30px'}}>
      <Button title="More Interviews" href="/interviews" />
    </Row>
    </div>
    )
  }
}

const Jobs = (props) => {
    //var jobs = [];
    //for (let i = 0; i < 6; i++)
      //jobs.push(props.jobs[Math.floor(Math.random() * props.jobs.length)])
    const jobs = props.jobs.sort(() => Math.random() - 0.5).slice(0, 6);
    return (  
      <div>
        <Row noGutters>
          <Col md={{span: 10, offset: 1}} style={{padding: 0}}>
            {jobs && jobs.map((job, i) => (
                <Job {...job} />
            ))}
          </Col>
        </Row>
        <Row noGutters style={{textAlign: 'center', marginTop: '30px'}}>
          <Button title="More Jobs" href="/jobs" />
        </Row>
      </div>
    )
}

const News = (props) => (
  <div>
    <Row noGutters style={{paddingBottom: '30px'}}>
      <Col md={{span: 10, offset: 1}} style={{padding: 0}}>
        {props.articles && props.articles.splice(0,6).map((article, i) => (
          <Article {...article} />
        ))}
      </Col>
    </Row>
    <Row noGutters style={{textAlign: 'center'}}>
      <Button title="More News" href="/news" />
    </Row>
  </div>
)

class Team extends React.Component {
  state = {selectedPerson: null}

  render() {
    return (
  <Row noGutters>
    <Col md={{span: 12, offset: 0}} style={{padding: 0}}>
    <div className="progression-row pro-team-grid" style={{paddingTop: 0}}>
      <div className="progression-team">
        {this.props.team.map((person) => {
          return <a href="#" onClick={(e) => {e.preventDefault(); this.setState({selectedPerson: person})}}><Person data={person} /></a>
        })}
      </div>
    </div>
    <Modal
      show={this.state.selectedPerson}
      onHide={() => this.setState({selectedPerson: null})}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
    <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{fontFamily: `'Noticia Text', serif`}}>
          {this.state.selectedPerson && this.state.selectedPerson.Name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{fontFamily: `'Lato', sans-serif`}}>
          {this.state.selectedPerson && this.state.selectedPerson.Description}
        </p>
        <div className="social-icons-team" style={{textAlign: 'center', marginTop: '10px'}}>
        </div>
      </Modal.Body>
    </Modal>
  </Col>
  </Row>
  )
  }
}
